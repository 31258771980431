@font-face {
  font-family: 'Droid Serif';
  font-style: normal;
  font-weight: normal;
  src: local('Droid Serif Regular'), url('./DroidSerif-Regular.woff2') format('woff2');
}
  
@font-face {
  font-family: 'Droid Serif';
  font-style: normal;
  font-weight: bold;
  src: local('Droid Serif Bold'), url('./DroidSerif-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Droid Serif';
  font-style: italic;
  font-weight: normal;
  src: local('Droid Serif Italic'), url('./DroidSerif-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Droid Serif';
  font-style: italic;
  font-weight: bold;
  src: local('Droid Serif BoldItalic'), url('./DroidSerif-BoldItalic.woff2') format('woff2');
}
