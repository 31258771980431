@font-face {
  font-family: 'Droid Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Droid Sans Regular'), url('./DroidSans.woff2') format('woff2');
}
  
@font-face {
  font-family: 'Droid Sans';
  font-style: normal;
  font-weight: bold;
  src: local('Droid Sans Bold'), url('./DroidSans-Bold.woff2') format('woff2');
}
